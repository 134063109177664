import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs';
import type { BaseOutput } from '@dextools/core';
import { ApiService, PathId } from '@dextools/core';
import { ChainUtil } from '@dextools/blockchains';
import type { AllPairDataResponse, ApiAllPairData, Alias, Chain, RepresentativePair, ApiTokenData } from '@dextools/blockchains';

@Injectable({
  providedIn: 'root',
})
export class PairsApiService {
  private readonly _representativePairs: Partial<Record<string, RepresentativePair>> = {} as Record<string, RepresentativePair>;

  public constructor(private readonly _apiService: ApiService) {}

  /**
   * Used to get the representative pair of a token
   *
   * @param token - String with the address of token
   * @param chain - Token chain
   * @returns Observable that will emit the representative pair
   */
  public getRepresentativePairByToken(token: string, chain: Chain): Observable<RepresentativePair | null> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const chainToken = `${chain}-${token}`;
    if (this._representativePairs[chainToken]) {
      return of(this._representativePairs[chainToken] as RepresentativePair);
    } else {
      const ignoreFields = [
        'id',
        'name',
        'symbol',
        'decimals',
        'creationBlock',
        'creationTime',
        'totalSupply',
        'metrics',
        'audit',
        'locks',
        'info',
        'links',
        'logo',
      ];

      const legacyChain = ChainUtil.getLegacyChain(chain);
      const params = `?tokens=${legacyChain}:${token}&ignoreFields=${ignoreFields}`;

      return this._apiService.get<BaseOutput<ApiTokenData[]>>(PathId.SHARED, `/data/token${params}`).pipe(
        map<BaseOutput<ApiTokenData[] | null>, RepresentativePair | null>((response) => {
          if (response.data && response.data.length > 0) {
            const token: ApiTokenData | null = response.data[0] as ApiTokenData | null;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (token === null || Object.keys(token).length === 0 || !token.reprPair?.id) {
              return null;
            }

            this._representativePairs[chainToken] = token.reprPair;
            return this._representativePairs[chainToken] as RepresentativePair;
          }
          return null;
        }),
      );
    }
  }

  /**
   * Used to get all info about a pair knowing its alias.
   *
   * @param alias - Alias of the pair.
   * @param audit - If we want to obtain audits.
   * @param locks - If we want to obtain locks.
   *
   * @returns Observable containing all info about the pair.
   */
  public getAllAliasData(alias: string, audit = false, locks = true): Observable<Alias | null> {
    const aliasParam = `alias=${alias}`;
    const auditParam = `audit=${audit}`;
    const locksParam = `locks=${locks}`;
    const params = `${aliasParam}&${auditParam}&${locksParam}`;

    return this._getPairOrAliasData(params).pipe(
      map<BaseOutput<AllPairDataResponse | null>, Alias | null>((response) => {
        if (response.data === null) {
          return null;
        }

        return {
          chain: ChainUtil.replaceLegacyChain(response.data[0].id.chain),
          pair: response.data[0].id.pair,
          alias: response.data[0].alias ?? '',
        };
      }),
    );
  }

  /**
   * Used to get all info about a pair knowing its address.
   *
   * @param pairId - Id of the pair.
   * @param chain - Current chain
   * @param audit - If we want to obtain audits.
   * @param locks - If we want to obtain locks.
   *
   * @returns Observable containing all info about the pair.
   */
  public getAllPairData(pairId: string, chain: Chain, audit = false, locks = true): Observable<ApiAllPairData | null> {
    const finalChain = ChainUtil.getLegacyChain(chain);
    const chainParam = `chain=${finalChain}`;
    const pairIdParam = `address=${pairId}`;
    const auditParam = `audit=${audit}`;
    const locksParam = `locks=${locks}`;
    const params = `${pairIdParam}&${chainParam}&${auditParam}&${locksParam}`;

    return this._getPairOrAliasData(params).pipe(
      map<BaseOutput<AllPairDataResponse | null>, ApiAllPairData | null>((response) => {
        if (response.data === null) {
          return null;
        } else {
          return response.data[0];
        }
      }),
    );
  }

  /**
   * Call to pair data endpoint
   *
   * @param params - All params necessary to get info about a pair. Setted in getAllAliasData and getAllPairData.
   *
   * @returns Observable containing all info about a pair.
   */
  private _getPairOrAliasData(params: string): Observable<BaseOutput<AllPairDataResponse | null>> {
    return this._apiService.get<BaseOutput<AllPairDataResponse>>(PathId.SHARED, `/data/pair?${params}`);
  }
}
